export const normalizeVariantInventoryLevel = variant => {
  return {
    ...variant,
    weight: variant?.inventoryItem?.measurement?.weight?.value,
    currentAvailable: variant?.inventoryQuantity || 0
  }
}

export const getVariantInventoryLevel = variants => {
  return variants?.map(normalizeVariantInventoryLevel)
}
