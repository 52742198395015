import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated, config } from '@react-spring/web'
import { ImageContainer, ImageStyled, EmojiBox } from './styles'

/**
 * Componente que muestra una imagen con soporte para dos formatos (WebP y SVG),
 * y un emoji animado que aparece sobre la imagen. Utiliza animaciones con react-spring.
 *
 * @component
 * @param {Object} props - Las propiedades del componente.
 * @param {string} props.webpSrc - La fuente de la imagen en formato WebP.
 * @param {string} props.svgSrc - La fuente de la imagen en formato SVG.
 * @param {string} props.alt - El texto alternativo para la imagen, requerido para accesibilidad.
 * @param {string} props.emoji - El emoji que se mostrará animado sobre la imagen.
 * @returns {JSX.Element} - Retorna un componente de imagen con emoji animado.
 *
 * @example
 * // Uso básico del componente con una imagen WebP y SVG, y un emoji animado.
 * <ImageWithEmoji
 *   webpSrc="https://example.com/image.webp"
 *   svgSrc="https://example.com/image.svg"
 *   alt="Ejemplo de imagen con emoji"
 *   emoji="🎉"
 * />
 */
const ImageWithEmoji = ({ webpSrc, svgSrc, alt, emoji, ...props }) => {
  /**
   * Estado que maneja la fuente actual de la imagen (WebP o SVG).
   * @type {[string, function]} - Una tupla con la fuente de la imagen y una función para actualizarla.
   */
  const [currentSrc, setCurrentSrc] = useState(webpSrc)

  /**
   * Estado que controla si la imagen está cargando.
   * @type {[boolean, function]} - Una tupla con el estado de carga y una función para actualizarlo.
   */
  const [loading, setLoading] = useState(true)

  /**
   * Maneja la carga del SVG, cambiando la fuente de la imagen y marcando la carga como completada.
   *
   * @function
   * @name handleSvgLoad
   * @example
   * <img src={currentSrc} onLoad={handleSvgLoad} />
   */
  const handleSvgLoad = useCallback(() => {
    setCurrentSrc(svgSrc)
    setLoading(false)
  }, [svgSrc])

  /**
   * Maneja la carga de la imagen WebP, marcando el estado de carga como completado.
   *
   * @function
   * @name handleWebpLoad
   */
  const handleWebpLoad = useCallback(() => {
    setLoading(false)
  }, [])

  /**
   * Propiedades de animación para la imagen principal, usando react-spring.
   */
  const springProps = useSpring({
    opacity: 1,
    transform: 'scale(1)',
    from: { opacity: 0, transform: 'scale(0.5)' },
    config: { ...config.wobbly }
  })

  /**
   * Propiedades de animación para el emoji, usando react-spring.
   */
  const emojiAnimation = useSpring({
    from: { opacity: 0.5, transform: 'scale(0.4)' },
    to: { opacity: 1, transform: 'scale(1.2)' },
    config: { ...config.wobbly, friction: 20 },
    reset: true
  })

  return (
    <ImageContainer>
      <ImageStyled
        src={currentSrc}
        alt={alt}
        style={springProps}
        onLoad={currentSrc === webpSrc ? handleWebpLoad : handleSvgLoad}
        loading={String(loading)}
        {...props}
      />
      <animated.div style={emojiAnimation}>
        <EmojiBox>{emoji}</EmojiBox>
      </animated.div>
    </ImageContainer>
  )
}

ImageWithEmoji.propTypes = {
  webpSrc: PropTypes.string,
  svgSrc: PropTypes.string,
  alt: PropTypes.string.isRequired,
  emoji: PropTypes.string.isRequired
}

ImageWithEmoji.defaultProps = {
  webpSrc: '',
  svgSrc:
    'https://www.kitchencenter.cl/cdn/shop/files/kitchencenter_logo.svg?v=1676480309&width=180',
  alt: 'alt',
  emoji: '🔒'
}

/**
 * Función para comparar las propiedades del componente y evitar renderizados innecesarios.
 *
 * @param {Object} prevProps - Las propiedades anteriores.
 * @param {Object} nextProps - Las nuevas propiedades.
 * @returns {boolean} - Retorna `true` si las propiedades son iguales, de lo contrario `false`.
 */
const areEqual = (prevProps, nextProps) => {
  return prevProps.emoji === nextProps.emoji
}

export default React.memo(ImageWithEmoji, areEqual)
