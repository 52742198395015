import { isObject } from 'lodash'

const normalizeProducts = products => {
  let newProducts = []
  for (const prop of Object.keys(products)) {
    if (Array.isArray(products[prop])) {
      for (const item of products[prop]) {
        if (isObject(item)) {
          for (const pro of Object.keys(item)) {
            const obj = {}
            Object.entries(item[pro]).forEach(value => {
              const key = value[0]
              const val = value[1]
              if (!isObject(val) || key === 'product') {
                obj[key] = val
              } else {
                const arr = []
                obj[key] = val
                Object.values(val).forEach(fields => {
                  if (Array.isArray(fields)) {
                    fields.forEach(field => {
                      arr.push(Object.assign({}, Object.values(field)[0]))
                    })
                    obj['count'] = fields.length
                    obj[key] = arr
                  }
                })
              }
            })
            newProducts.push(obj)
          }
        }
      }
    } else if (isObject(products[prop])) {
      return normalizeProducts(products[prop])
    }
  }
  newProducts = newProducts.map(product => ({
    ...product,
    totalInventory: (product.variants || []).reduce(
      (prev, variant) => prev + (variant?.inventoryQuantity || 0),
      0
    )
  }))

  return newProducts
}

export default normalizeProducts
