/**
 * Normaliza los datos del usuario con el token de autenticación.
 * Esta función genera un objeto de usuario que incluye la información básica
 * y el rol personalizado, si está disponible.
 *
 * @param {string} token - El token de autenticación del usuario.
 * @returns {function(Object): Object} - Una función que acepta un objeto de usuario y retorna un objeto normalizado.
 *
 * @example
 * // Ejemplo de uso
 * const user = {
 *   displayName: 'John Doe',
 *   email: 'john.doe@example.com',
 *   photoURL: 'https://example.com/photo.jpg',
 *   permissions: [{ appId: '123', role: 'admin' }],
 *   uid: 'user123',
 * };
 * const token = 'abcdef123456';
 * const normalizedUser = normalizeUser(token)(user);
 * console.log(normalizedUser);
 * // {
 * //   token: 'abcdef123456',
 * //   name: 'John Doe',
 * //   email: 'john.doe@example.com',
 * //   picture: 'https://example.com/photo.jpg',
 * //   photoURL: 'https://example.com/photo.jpg',
 * //   role: 'admin',
 * //   uid: 'user123',
 * //   permissions: [{ appId: '123', role: 'admin' }],
 * //   displayName: 'John Doe'
 * // }
 *
 * @see https://firebase.google.com/docs/reference/js/firebase.auth.UserCredential - Documentación de Firebase User
 *
 *
 * @author
 * Alejandro Vega Piña 🤓
 */
export const normalizeUser =
  token =>
  ({ displayName, email, photoURL, permissions, uid, ...user }) => {
    const customRole = permissions?.find(
      permission =>
        permission?.appId?.toString() ===
        process.env.REACT_APP_APP_ID.toString()
    )?.role

    return {
      ...user,
      token,
      name: displayName,
      email,
      picture: photoURL,
      photoURL,
      role: customRole,
      uid,
      permissions,
      displayName
    }
  }

/**
 * Rol predeterminado del usuario en la aplicación.
 * Los roles disponibles son:
 * - "admin": Tiene acceso completo a todas las funcionalidades y configuraciones de la aplicación.
 * - "viewer": Solo puede ver información, sin permisos para realizar modificaciones.
 * - "operations": Puede realizar operaciones específicas asignadas, como la gestión de ciertos recursos,
 *   pero sin acceso a configuraciones administrativas.
 *
 * @type {string}
 * @default "viewer"
 */
const defaultRole = 'viewer'

/**
 * Normaliza la autenticación del usuario para obtener un objeto con la información esencial.
 * Esta función asigna un rol personalizado basado en los permisos del usuario, o un rol predeterminado si no se encuentra.
 *
 * @param {Object} user - El objeto de usuario que contiene la información de autenticación. Ver la [documentación oficial de Firebase User](https://firebase.google.com/docs/reference/js/firebase.User).
 * @param {string} user.email - El correo electrónico del usuario.
 * @param {string} user.photoURL - La URL de la foto del usuario.
 * @param {Array<Object>} user.permissions - Lista de permisos del usuario.
 * @param {string} user.permissions[].appId - El identificador de la aplicación para la cual se asigna el permiso.
 * @param {string} user.permissions[].role - El rol del usuario en la aplicación especificada (e.g., "admin", "viewer", "operations").
 * @param {string} user.uid - El identificador único del usuario.
 * @param {string} [user.name] - El nombre del usuario.
 * @param {string} [user.picture] - La URL de la imagen del usuario (puede ser redundante con `photoURL`).
 * @param {string} [user.displayName] - El nombre para mostrar del usuario.
 *
 * @returns {Object} - Un objeto con la información normalizada del usuario.
 * @returns {string} return.email - El correo electrónico del usuario.
 * @returns {string} return.picture - La URL de la imagen del usuario. Si no está disponible `photoURL`, se usa `picture`.
 * @returns {string} return.role - El rol del usuario, asignado a partir de los permisos o el rol predeterminado "viewer".
 * @returns {string} return.uid - El identificador único del usuario.
 * @returns {Array<Object>} return.permissions - Lista de permisos del usuario, que incluyen roles para diferentes aplicaciones.
 * @returns {string} return.permissions[].appId - El identificador de la aplicación para la cual se otorga el permiso.
 * @returns {string} return.permissions[].role - El rol del usuario en la aplicación especificada (e.g., "admin", "viewer").
 * @returns {string} return.displayName - El nombre para mostrar del usuario, asignado a partir de `name` o `displayName`.
 *
 * @example
 * // Ejemplo de uso
 * const authFirebase = {
 *   email: 'jane.doe@example.com',
 *   photoURL: 'https://example.com/jane.jpg',
 *   permissions: [{ appId: '123', role: 'viewer' }],
 *   uid: 'user456',
 *   name: 'Jane Doe'
 * };
 * const normalizedAuth = normalizeAuth(authFirebase);
 * console.log(normalizedAuth);
 * // {
 * //   email: 'jane.doe@example.com',
 * //   picture: 'https://example.com/jane.jpg',
 * //   role: 'viewer',
 * //   uid: 'user456',
 * //   permissions: [{ appId: '123', role: 'viewer' }],
 * //   displayName: 'Jane Doe'
 * // }
 *
 * @see https://firebase.google.com/docs/auth/web/manage-users - Documentación de manejo de usuarios en Firebase
 *
 * @author
 * Alejandro Vega Piña 🤓
 */
export const normalizeAuth = ({
  email,
  photoURL,
  permissions,
  uid,
  name,
  picture,
  displayName
}) => {
  const customRole = permissions?.find(
    permission =>
      permission?.appId?.toString() ===
      process.env.REACT_APP_APP_ID.toString()
  )?.role

  return {
    email,
    picture: photoURL || picture,
    role: customRole ? String(customRole) : defaultRole,
    uid,
    permissions,
    displayName: name || displayName
  }
}
