// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

/**
 * Registra el Service Worker en una aplicación React PWA.
 * Esta función maneja el registro del Service Worker, la validación en entornos localhost
 * y la verificación de la compatibilidad del navegador con los Service Workers.
 *
 * @param {Object} config - Opciones de configuración para manejar eventos específicos del Service Worker.
 * @param {function} [config.onUpdate] - Función de callback que se ejecuta cuando se encuentra una actualización del Service Worker.
 * @param {function} [config.onSuccess] - Función de callback que se ejecuta cuando el Service Worker se registra exitosamente.
 *
 * @description
 * La función `register` registra un Service Worker para una aplicación React PWA.
 * Realiza las siguientes tareas:
 * 1. Verifica si la aplicación está en producción y si el navegador soporta Service Workers.
 * 2. Comprueba si la URL pública de la aplicación tiene el mismo origen que la URL actual. Si no, cancela el registro.
 * 3. Registra el Service Worker cuando la ventana se carga. Si se está ejecutando en localhost, valida si el Service Worker
 *    sigue existiendo, y si no, realiza un registro completo.
 *
 * @example
 * // Uso típico en una configuración de React PWA
 * register({
 *   onUpdate: registration => {
 *     alert('Nueva versión disponible. Por favor, actualiza la aplicación.');
 *   },
 *   onSuccess: registration => {
 *     console.log('El Service Worker se ha registrado con éxito.');
 *   }
 * });
 *
 * @see https://create-react-app.dev/docs/making-a-progressive-web-app/ - Documentación de PWA para Create React App
 * @see https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle - Documentación sobre el ciclo de vida del Service Worker
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API/Using_Service_Workers - Uso de Service Workers en MDN
 */
export function register(config) {
  if (
    process.env.NODE_ENV === 'production' &&
    'serviceWorker' in navigator
  ) {
    // El constructor de URL está disponible en todos los navegadores que soportan Service Workers.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // El Service Worker no funcionará si PUBLIC_URL está en un origen diferente al de la página.
      // Esto podría suceder si se usa un CDN para servir los assets.
      return
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

      if (isLocalhost) {
        // Si se está ejecutando en localhost, se verifica si un Service Worker todavía existe.
        checkValidServiceWorker(swUrl, config)

        // Agregar registros adicionales para localhost, apuntando a la documentación del Service Worker/PWA.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'Esta aplicación web está siendo servida con una estrategia cache-first ' +
              'por un Service Worker. Para más información, visita https://cra.link/PWA'
          )
        })
      } else {
        // No es localhost, solo registra el Service Worker
        registerValidSW(swUrl, config)
      }
    })
  }
}

/**
 * Registra un Service Worker para habilitar el soporte PWA en la aplicación.
 * Esta función maneja el registro del Service Worker, la verificación periódica de actualizaciones y el manejo del ciclo de vida del Service Worker.
 *
 * @param {string} swUrl - La URL del archivo del Service Worker. Normalmente es `/service-worker.js` o una ruta similar.
 * @param {Object} [config] - Opciones de configuración para manejar eventos específicos del Service Worker.
 * @param {function} [config.onUpdate] - Función de callback que se ejecuta cuando se encuentra una actualización del Service Worker.
 * @param {function} [config.onSuccess] - Función de callback que se ejecuta cuando el Service Worker se registra exitosamente.
 *
 * @returns {Promise<ServiceWorkerRegistration>} - Una promesa que se resuelve con el objeto `ServiceWorkerRegistration` que representa el registro del Service Worker.
 *
 * @description
 * Esta función registra un Service Worker y realiza las siguientes acciones:
 * 1. Intenta registrar el Service Worker en la URL proporcionada (`swUrl`).
 * 2. Actualiza el Service Worker manualmente después de cada intervalo de 15 minutos para verificar si hay actualizaciones.
 * 3. Maneja los cambios de estado del Service Worker, incluyendo la instalación y la actualización.
 * 4. Ejecuta los callbacks de `onUpdate` y `onSuccess` si se proporcionan, cuando hay una actualización o cuando el registro es exitoso, respectivamente.
 *
 * @example
 * // Ejemplo de uso en un archivo de configuración PWA
 * registerValidSW('/service-worker.js', {
 *   onUpdate: registration => {
 *     alert('Hay una nueva actualización disponible. Por favor, actualiza la página.');
 *   },
 *   onSuccess: registration => {
 *     console.log('El contenido está disponible para uso sin conexión.');
 *   }
 * });
 *
 * @see https://create-react-app.dev/docs/making-a-progressive-web-app/ - Documentación de PWA para Create React App
 * @see https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle - Documentación sobre el ciclo de vida del Service Worker
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API/Using_Service_Workers - Uso de Service Workers en MDN
 */
function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.update()

      // Verifica actualizaciones cada 15 minutos
      setInterval(
        () => {
          registration.update()
          console.info(
            'Buscando actualización de la aplicación cada 15 minutos...',
            new Date().toLocaleDateString()
          )
        },
        1000 * 60 * 15
      ) // 1000 (milisegundos) * 60 (segundos) * 15 (minutos) = 900,000 milisegundos

      // Manejar los cambios en el estado del Service Worker
      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        if (installingWorker == null) {
          return
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // El contenido actualizado ha sido precargado, pero el Service Worker anterior
              // aún se está utilizando hasta que todas las pestañas sean cerradas.
              console.log(
                'Nuevo contenido disponible y se usará cuando todas ' +
                  'las pestañas abiertas de esta página sean cerradas. Ver https://cra.link/PWA.'
              )

              // Ejecutar callback en caso de actualización
              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
            } else {
              // Todo el contenido ha sido precargado para uso sin conexión
              console.log(
                'El contenido está disponible para uso sin conexión.'
              )

              // Ejecutar callback en caso de registro exitoso
              if (config && config.onSuccess) {
                config.onSuccess(registration)
              }
            }
          }
        }
      }

      return registration // Retorna el objeto de registro del Service Worker
    })
    .catch(error => {
      console.error('Error durante el registro del Service Worker:', error)
    })
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' }
  })
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config)
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    })
}

/**
 * Desregistra el Service Worker en la aplicación PWA.
 * Esta función elimina el registro del Service Worker si está activo en el navegador.
 *
 * @description
 * La función `unregister` se encarga de eliminar el registro del Service Worker,
 * lo que desactiva sus funciones de almacenamiento en caché y de trabajo sin conexión.
 * Es útil para casos en los que se desea volver a una versión no PWA de la aplicación
 * o al realizar pruebas en desarrollo. La función primero verifica si el navegador soporta
 * Service Workers y si hay algún Service Worker listo, luego lo desregistra.
 *
 * @example
 * // Uso típico para desregistrar el Service Worker en un componente React
 * import { unregister } from './serviceWorkerRegistration';
 *
 * useEffect(() => {
 *   // Desregistrar el Service Worker al desmontar el componente
 *   return () => unregister();
 * }, []);
 *
 * @see https://create-react-app.dev/docs/making-a-progressive-web-app/#unregistering-service-workers - Documentación sobre desregistro en Create React App
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API/Using_Service_Workers - Uso de Service Workers en MDN
 */
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister()
      })
      .catch(error => {
        console.error(error.message)
      })
  }
}
