import { useEffect, useState, useCallback, useMemo } from 'react'
import persistentStore from '../../../data/persistentStore'

/**
 * Verifica si el navegador admite el cambio de esquema de color (modo oscuro o claro).
 *
 * @returns {boolean} - Retorna `true` si el navegador soporta el cambio de modo oscuro, de lo contrario, `false`.
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme - Documentación sobre `prefers-color-scheme`.
 */
const isSupportedBrowserDarkMode = () =>
  window.matchMedia('(prefers-color-scheme)').media !== 'not all'

/**
 * Hook personalizado para gestionar el tema de la aplicación (dark o light).
 * Permite cambiar automáticamente entre el modo claro y oscuro en función de la preferencia del usuario o el sistema.
 * El tema se almacena de forma persistente para mantener la preferencia en recargas futuras.
 *
 * @returns {[string, function]} - Un arreglo con el tema actual y una función para cambiar el tema.
 *
 * @typedef {Array} ThemeHook
 * @property {string} modeTheme - El tema actual de la aplicación, puede ser 'light' o 'dark'.
 * @property {function} setModeTheme - Función para cambiar el tema de la aplicación.
 */
const useStoreTheme = () => {
  /**
   * Media query para detectar si el modo oscuro está activado.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia - Documentación sobre `matchMedia`.
   */
  const darkModeMediaQuery = useMemo(
    () => window.matchMedia('(prefers-color-scheme: dark)'),
    []
  )
  /**
   * @type {string} modeTheme - El tema actual, puede ser 'light' o 'dark'.
   * @type {function} setModeTheme - Función para cambiar el tema actual.
   */
  const [modeTheme, setModeTheme] = useState(() => {
    return persistentStore.getTheme() || 'light'
  })

  // useCallback para mantener la referencia de setModeTheme y evitar renderizados innecesarios
  const updateThemeInStore = useCallback(theme => {
    persistentStore.setTheme(theme)
  }, [])

  // Sincroniza el tema con el almacenamiento persistente si ha cambiado
  useEffect(() => {
    const storedTheme = persistentStore.getTheme()
    if (storedTheme !== modeTheme) {
      updateThemeInStore(modeTheme)
    }
  }, [modeTheme, updateThemeInStore])

  // useEffect para detectar el cambio en el esquema de color
  useEffect(() => {
    if (isSupportedBrowserDarkMode()) {
      const handleChange = event => {
        const darkModeOn = event.matches
        setModeTheme(darkModeOn ? 'dark' : 'light')
      }

      darkModeMediaQuery.addEventListener('change', handleChange)

      return () => {
        darkModeMediaQuery.removeEventListener('change', handleChange)
      }
    }
  }, [darkModeMediaQuery])

  return [modeTheme, setModeTheme]
}

export default useStoreTheme
