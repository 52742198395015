import React, { useState, useEffect, useCallback } from 'react'
import { useSpring, config } from '@react-spring/web'
import { MessageBox } from './styles'
import PropTypes from 'prop-types'

/**
 * Lista de mensajes con sus tiempos de lectura y emojis correspondientes.
 * @type {Array<Object>}
 * @property {string} text - El texto del mensaje a mostrar.
 * @property {number} readingTime - El tiempo en milisegundos que el mensaje será visible.
 * @property {string} emoji - El emoji asociado con el mensaje.
 */
const messages = [
  {
    text: 'Validando su sesión por seguridad',
    readingTime: 2500,
    emoji: '🔒'
  },
  { text: 'Cargando configuración', readingTime: 2000, emoji: '⚙️' },
  { text: 'Verificando integridad', readingTime: 1500, emoji: '🔍' },
  { text: 'Preparando contenido', readingTime: 1000, emoji: '📦' },
  { text: '🚚 Casi listo', readingTime: 3000, emoji: '✅' }
]

/**
 * Componente `MessageQueue` que muestra una lista de mensajes de forma secuencial con animación.
 * Cada mensaje se muestra durante un tiempo determinado y cambia automáticamente.
 * Además, cambia el emoji mostrado en un componente padre a través de la función `onEmojiChange`.
 *
 * @component
 * @param {Object} props - Las propiedades del componente.
 * @param {function} props.onEmojiChange - Función para cambiar el emoji en el componente padre.
 * @returns {JSX.Element} - El componente `MessageQueue` con el mensaje animado.
 *
 * @example
 * // Ejemplo de uso en un componente principal
 * import MessageQueue from './MessageQueue';
 *
 * function App() {
 *   const handleEmojiChange = (emoji) => {
 *     console.log('Nuevo emoji:', emoji);
 *   };
 *
 *   return (
 *     <div>
 *       <MessageQueue onEmojiChange={handleEmojiChange} />
 *     </div>
 *   );
 * }
 *
 * export default App;
 */
const MessageQueue = ({ onEmojiChange }) => {
  /**
   * Estado para manejar el índice del mensaje actual en la lista de `messages`.
   * @type {[number, function]} - El índice del mensaje actual y la función para actualizarlo.
   */
  const [index, setIndex] = useState(0)

  /**
   * Actualiza el índice del mensaje para pasar al siguiente mensaje en la lista.
   * Cuando se alcanza el final de la lista, vuelve al inicio.
   */
  const nextEmoji = useCallback(
    () => setIndex(prevIndex => (prevIndex + 1) % messages.length),
    []
  )

  /**
   * Configuración de la animación para mostrar el mensaje actual con un efecto de desvanecimiento.
   */
  const { opacity, transform } = useSpring({
    opacity: 1,
    transform: 'translate(0%)',
    from: { opacity: 0.5, transform: 'translateY(-15%)' },
    reset: true,
    loop: true,
    onRest: nextEmoji,
    config: {
      ...config.wobbly,
      duration: messages[index]?.readingTime
    }
  })

  /**
   * Actualiza el emoji en el componente padre utilizando `onEmojiChange`.
   */
  const updateEmoji = useCallback(() => {
    onEmojiChange(messages[index]?.emoji)
  }, [index, onEmojiChange])

  /**
   * Efecto para actualizar el emoji cada vez que cambia el índice del mensaje.
   */
  useEffect(() => {
    updateEmoji()
  }, [index, updateEmoji])

  /**
   * Efecto para garantizar que `nextEmoji` solo se ejecute si el componente está montado.
   */
  useEffect(() => {
    let isMounted = true

    const originalNextEmoji = nextEmoji

    const safeNextEmoji = () => {
      if (isMounted) {
        originalNextEmoji()
      }
    }

    return () => {
      safeNextEmoji()
      isMounted = false
    }
  }, [nextEmoji])

  return (
    <MessageBox style={{ opacity, transform }}>
      {messages[index]?.text}...
    </MessageBox>
  )
}

MessageQueue.propTypes = {
  onEmojiChange: PropTypes.func.isRequired
}

export default MessageQueue
