import { useEffect, useState, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'

/**
 * Hook personalizado para gestionar la instalación de una PWA (Progressive Web App).
 * Permite capturar el evento `beforeinstallprompt` y controlar el flujo de instalación de la aplicación.
 *
 * ### Pasos Realizados:
 * 1. Captura el evento `beforeinstallprompt` cuando la PWA es instalable.
 * 2. Permite al usuario iniciar la instalación manualmente llamando a `prompt()`.
 * 3. Detecta si la aplicación ha sido instalada y actualiza el estado.
 *
 * @returns {Object} - Un objeto con dos propiedades:
 *   - `isSupportsPWA` (`boolean`): Indica si la instalación de la PWA es soportada por el navegador.
 *   - `onInstallApp` (`function`): Función para iniciar la instalación de la PWA.
 *
 * @example
 * // Ejemplo de uso en un componente React
 * import React from 'react';
 * import useInstallPWA from './hooks/useInstallPWA';
 *
 * function App() {
 *   const { isSupportsPWA, onInstallApp } = useInstallPWA();
 *
 *   return (
 *     <div>
 *       <h1>Mi Aplicación PWA</h1>
 *       {isSupportsPWA && (
 *         <button onClick={onInstallApp}>Instalar la PWA</button>
 *       )}
 *     </div>
 *   );
 * }
 *
 * export default App;
 *
 * @see https://web.dev/customize-install/#beforeinstallprompt - Documentación oficial sobre `beforeinstallprompt`.
 * @see https://web.dev/customize-install/#customize-the-install - Documentación oficial para personalizar la experiencia de instalación.
 *
 * @author
 * Alejandro Vega Piña 🤓
 */
const useInstallPWA = () => {
  /**
   * Evento capturado para la instalación de la PWA.
   * El navegador almacena un evento `beforeinstallprompt` cuando la PWA es instalable.
   *
   * ### Información sobre `window.deferredPrompt`:
   * `window.deferredPrompt` es una propiedad global utilizada para almacenar el evento `beforeinstallprompt`.
   * Permite acceder al evento desde otras partes de la aplicación y controlar cuándo se muestra el aviso de instalación.
   *
   * @type {BeforeInstallPromptEvent|null}
   * @see https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent - Documentación oficial del evento BeforeInstallPrompt.
   */
  const promptEvent = window?.deferredPrompt

  /**
   * Indica si la instalación de la PWA es soportada por el navegador.
   *
   * @type {boolean}
   */
  const [isSupportsPWA, setSupportsPWA] = useState(false)

  /**
   * Evento de instalación de la PWA capturado.
   * Se utiliza para mostrar el aviso de instalación manualmente.
   *
   * @type {BeforeInstallPromptEvent|null}
   */
  const [promptInstall, setPromptInstall] = useState(promptEvent)

  /**
   * Manejador del evento `beforeinstallprompt` para capturar el aviso de instalación.
   * Este evento ocurre cuando el navegador detecta que la PWA es instalable.
   *
   * ## Pasos Realizados:
   * 1. Previene el comportamiento predeterminado del navegador para no mostrar el aviso de instalación automáticamente.
   * 2. Indica que la PWA es soportada y está disponible para ser instalada (`setSupportsPWA(true)`).
   * 3. Guarda el evento `beforeinstallprompt` en el estado para poder usarlo posteriormente.
   * 4. Almacena el evento en `window.deferredPrompt` globalmente, lo que permite acceder al evento desde otras partes de la aplicación si es necesario.
   *
   * ### El evento almacenado en `promptInstall` contiene las siguientes propiedades y métodos clave:
   * - `prompt()`: Muestra el aviso de instalación al usuario.
   * - `userChoice`: Una promesa que se resuelve cuando el usuario elige si quiere instalar la PWA o no. El resultado indica si la instalación fue aceptada o rechazada.
   *
   * #### Consecuencias:
   * - Al capturar el evento, se evita que el navegador muestre el aviso de instalación predeterminado, dando control total sobre cuándo y cómo se solicita al usuario que instale la PWA.
   *
   * #### Ejemplo de contenido del `BeforeInstallPromptEvent`:
   * ```javascript
   * {
   *   platforms: ["web"],
   *   userChoice: Promise, // Indica la elección del usuario (aceptada o rechazada).
   *   prompt: function() // Muestra el aviso de instalación.
   * }
   * ```
   *
   * #### Uso de `setPromptInstall`:
   * La función `setPromptInstall` se utiliza para actualizar el estado de `promptInstall`, almacenando el evento capturado.
   * Este estado se usa posteriormente para llamar a `promptInstall.prompt()` y mostrar el aviso de instalación al usuario.
   *
   * @type {BeforeInstallPromptEvent|null}
   * @param {Event} e - El evento `beforeinstallprompt` que contiene información sobre el contexto en el que el navegador permite la instalación de la PWA.
   * @see https://web.dev/customize-install/#beforeinstallprompt - Documentación oficial sobre el evento `beforeinstallprompt`.
   */
  const handlerPrompt = useCallback(e => {
    e.preventDefault() // Previene el comportamiento predeterminado del navegador.
    console.info('Captura de A2HS 🧐')
    setSupportsPWA(true) // Indica que la instalación es soportada.
    setPromptInstall(e) // Almacena el evento para usarlo más tarde.
    window.deferredPrompt = e // Guarda el evento globalmente para referencia futura.
  }, [])

  /**
   * Inicia el proceso de instalación de la PWA cuando el usuario hace clic en el botón.
   * Llama al método `prompt()` del evento `beforeinstallprompt` almacenado.
   *
   * ### Pasos Realizados:
   * 1. Previene el comportamiento predeterminado del evento para asegurar un flujo de instalación controlado.
   * 2. Verifica si existe un evento `beforeinstallprompt` almacenado (`promptInstall`).
   *    - Si no existe, muestra un mensaje indicando que no hay un evento disponible para la instalación.
   * 3. Si el evento `beforeinstallprompt` está disponible:
   *    - Llama al método `prompt()` para mostrar el aviso de instalación.
   *    - Espera la decisión del usuario (`userChoice`), que puede ser aceptar o rechazar la instalación.
   *    - Dependiendo de la elección, actualiza el estado de `isSupportsPWA`:
   *      - Si se acepta, establece `isSupportsPWA` en `false`, indicando que la PWA se instaló correctamente.
   *      - Si se rechaza, mantiene `isSupportsPWA` en `true` para que el usuario pueda intentar de nuevo.
   *
   * ### Consecuencias:
   * - Al llamar a `prompt()`, se inicia el proceso de instalación de la PWA, permitiendo al usuario decidir si instalarla.
   * - La función maneja adecuadamente tanto la aceptación como el rechazo, brindando feedback al usuario sobre la elección.
   *
   * @param {Event} e - El evento de clic en el botón de instalación.
   * @returns {void}
   * @see https://web.dev/customize-install/#customize-the-install - Documentación oficial para personalizar la experiencia de instalación de PWA.
   */
  const onPrompt = useCallback(
    e => {
      e.preventDefault() // Previene el comportamiento predeterminado.
      if (!promptInstall) {
        console.log('oops, no prompt event guardado en window')
        return null
      }
      promptInstall.prompt() // Inicia el flujo de instalación.
      promptInstall.userChoice.then(choiceResult => {
        // Maneja la elección del usuario (aceptar o rechazar).
        if (choiceResult.outcome === 'accepted') {
          console.info('El usuario aceptó el aviso de A2HS 🥳')
          setSupportsPWA(false)
        } else {
          console.info('El usuario descartó la solicitud de A2HS 😕')
          setSupportsPWA(true)
        }
      })
    },
    [promptInstall]
  )

  useEffect(() => {
    // Detecta el evento `beforeinstallprompt`
    window.addEventListener('beforeinstallprompt', handlerPrompt)
    // Detecta cuando la aplicación ha sido instalada
    window.addEventListener('appinstalled', () => {
      console.info('El usuario instaló la PWA 🤩')
      setSupportsPWA(false)
    })

    // Comprueba si existe un evento `beforeinstallprompt` en `window`
    if (!isEmpty(promptInstall)) {
      setSupportsPWA(true)
    }

    // Limpieza del listener al desmontar el componente
    return () => {
      window.removeEventListener('beforeinstallprompt', handlerPrompt)
      window.removeEventListener('appinstalled', handlerPrompt)
    }
  }, [handlerPrompt, promptInstall])

  return { isSupportsPWA, onInstallApp: onPrompt }
}

export default useInstallPWA
