import React, { useState, useCallback } from 'react'
import { Container } from './styles'
import ImageWithEmoji from './ImageWithEmoji'
import MessageQueue from './MessageQueue'
import logoSVG from '../../assets/logo.svg'
import logoWeb from '../../assets/logoWeb.webp'

/**
 * Componente `AuthValid` que muestra un logo con un emoji animado y un componente para gestionar mensajes.
 * Permite cambiar el emoji mostrado en la imagen mediante la función `handleEmojiChange`.
 *
 * @component
 * @returns {JSX.Element} - Componente que renderiza la imagen con emoji y el componente de mensajes.
 *
 * @example
 * // Uso en un componente principal de la aplicación
 * import AuthValid from './AuthValid';
 *
 * function App() {
 *   return (
 *     <div className="App">
 *       <AuthValid />
 *     </div>
 *   );
 * }
 *
 * export default App;
 */
const AuthValid = () => {
  /**
   * Estado que maneja el emoji actual a mostrar sobre la imagen.
   * @type {[string, function]} - Una tupla con el emoji actual y una función para actualizarlo.
   */
  const [emoji, setEmoji] = useState('🔒')

  /**
   * Maneja el cambio de emoji cuando se actualiza desde el componente `MessageQueue`.
   *
   * @function
   * @param {string} newEmoji - El nuevo emoji a mostrar.
   * @example
   * handleEmojiChange('😊');
   */
  const handleEmojiChange = useCallback(newEmoji => {
    setEmoji(newEmoji)
  }, [])

  return (
    <Container>
      <ImageWithEmoji
        webpSrc={logoWeb}
        svgSrc={
          logoSVG ||
          'https://www.kitchencenter.cl/cdn/shop/files/kitchencenter_logo.svg?v=1676480309&width=180'
        }
        alt="logo-kc"
        emoji={emoji}
      />
      <MessageQueue onEmojiChange={handleEmojiChange} />
    </Container>
  )
}

export default AuthValid
