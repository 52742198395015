import { useState, useEffect, useCallback } from 'react'
import isNil from 'lodash/isNil'
import * as serviceWorker from '../../serviceWorkerRegistration'

/**
 * Hook personalizado para manejar la lógica del Service Worker en una PWA.
 * Proporciona el estado del Service Worker y funciones para gestionar actualizaciones y recargar la página.
 * Este hook permite que la aplicación gestione de manera proactiva las actualizaciones del Service Worker,
 * lo cual es esencial para las aplicaciones PWA que buscan ofrecer contenido actualizado de forma automática.
 *
 * @returns {Object} - Un objeto que contiene el estado del Service Worker y las funciones para gestionar la actualización y recarga.
 * @property {boolean} isUpdateWorker - Indica si hay una actualización del Service Worker disponible.
 * @property {boolean} isSuccessWorker - Indica si el registro del Service Worker ha sido exitoso.
 * @property {function} reloadPage - Función que fuerza la recarga de la página y activa el nuevo Service Worker.
 *
 * @example
 * // Uso típico en un componente React para manejar actualizaciones del Service Worker
 * import React from 'react';
 * import useServiceWorker from './hooks/useServiceWorker';
 *
 * function App() {
 *   const { isUpdateWorker, isSuccessWorker, reloadPage } = useServiceWorker();
 *
 *   return (
 *     <div className="App">
 *       <h1>Bienvenido a la aplicación de Kitchen Center</h1>
 *       {isUpdateWorker && (
 *         <div className="update-notification">
 *           <p>Hay una nueva actualización disponible.</p>
 *           <button onClick={reloadPage}>Actualizar ahora</button>
 *         </div>
 *       )}
 *       {isSuccessWorker && (
 *         <p>El contenido está disponible para uso sin conexión.</p>
 *       )}
 *     </div>
 *   );
 * }
 *
 * export default App;
 *
 * @see https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle - Documentación del ciclo de vida del Service Worker en Google
 * @see https://create-react-app.dev/docs/making-a-progressive-web-app/ - Documentación oficial de Create React App sobre PWA
 * @see https://firebase.google.com/docs/auth/web/manage-users - Documentación de manejo de usuarios en Firebase
 *
 * @author
 * Alejandro Vega Piña 🤓
 */
const useServiceWorker = () => {
  const [registerWorker, setRegisterWorker] = useState(null)
  const [isUpdateWorker, setUpdateWorker] = useState(false)
  const [isSuccessWorker, setSuccessWorker] = useState(false)
  const [waitingWorker, setWaitingWorker] = useState(null)

  /**
   * Maneja la actualización del Service Worker.
   * Si existe un "waitingWorker" (Service Worker en estado de espera), lo activa de inmediato utilizando `SKIP_WAITING`,
   * lo cual hace que la nueva versión del Service Worker tome el control sin esperar a que los usuarios cierren las pestañas abiertas.
   * Luego, desregistra el Service Worker anterior y recarga la página para que los usuarios vean la nueva versión del contenido.
   *
   * @param {ServiceWorkerRegistration} registration - El objeto de registro del Service Worker.
   *
   * @see https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle - Documentación del ciclo de vida del Service Worker
   */
  const onSWUpdate = useCallback(async registration => {
    setUpdateWorker(true)
    setWaitingWorker(registration.waiting)
    setRegisterWorker(registration)

    if (registration && registration.waiting) {
      await registration.unregister()
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      window.location.reload()
    } else if (registration && isNil(registration.waiting)) {
      registration?.registrationWorker?.update()
      window.location.reload()
    }
  }, [])

  /**
   * Maneja el registro exitoso del Service Worker.
   * Actualiza el estado indicando que el Service Worker ha sido registrado con éxito.
   *
   * @param {ServiceWorkerRegistration} registration - El objeto de registro del Service Worker.
   */
  const onSWSuccess = useCallback(
    registration => {
      setSuccessWorker(true)
      setRegisterWorker(registration)
    },
    [setRegisterWorker]
  )

  /**
   * Fuerza la recarga de la página si hay un Service Worker en espera.
   * Activa el nuevo Service Worker enviando un mensaje `SKIP_WAITING`, lo cual permite que la nueva versión tome el control.
   * Si no hay un "waitingWorker", intenta actualizar el registro manualmente y luego recarga la página.
   *
   * @returns {Promise<void>} - Una promesa que se resuelve cuando la página se recarga.
   *
   * @example
   * // Uso típico para recargar la página cuando hay una actualización disponible
   * const { reloadPage } = useServiceWorker();
   * if (isUpdateWorker) {
   *   reloadPage().then(() => console.log('La página ha sido recargada.'));
   * }
   */
  const reloadPage = useCallback(async () => {
    setUpdateWorker(true)

    if (waitingWorker && registerWorker) {
      await registerWorker.unregister()
      waitingWorker.postMessage({
        type: 'SKIP_WAITING'
      })
      window.location.reload()
    } else if (isNil(waitingWorker) && registerWorker) {
      registerWorker?.registrationWorker?.update()
      window.location.reload()
    }
  }, [registerWorker, waitingWorker])

  useEffect(() => {
    // Registra el Service Worker y gestiona los callbacks de éxito y actualización.
    serviceWorker.register({
      onUpdate: onSWUpdate,
      onSuccess: onSWSuccess
    })
  }, [onSWSuccess, onSWUpdate])

  return {
    isUpdateWorker,
    isSuccessWorker,
    reloadPage
  }
}

export default useServiceWorker
