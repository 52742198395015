import {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
  useCallback
} from 'react'
import isEqual from 'lodash/isEqual'
import { auth } from './firebaseConfig'
import AuthValid from '../../../auth/AuthValid'
import { normalizeAuth } from '../../../../data/firebase/normalizeUser'
import persistentStore from '../../../../data/persistentStore'

/**
 * Contexto de autenticación para la aplicación.
 * @type {React.Context<Object>}
 */
const AuthContext = createContext()

/**
 * Hook personalizado para acceder al contexto de autenticación.
 *
 * @returns {Object} - El valor del contexto de autenticación, que incluye el usuario actual.
 *
 * @example
 * const { currentUser } = useAuth();
 */
export const useAuth = () => {
  return useContext(AuthContext)
}

/**
 * Proveedor de autenticación que encapsula la lógica de autenticación y proporciona
 * el estado de autenticación a los componentes hijos.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {React.ReactNode} props.children - Los componentes hijos que serán renderizados.
 *
 * @returns {JSX.Element} - Un componente proveedor que envuelve a los hijos con el contexto de autenticación.
 *
 * @example
 * <AuthProvider>
 *   <YourComponent />
 * </AuthProvider>
 */
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)

  /**
   * Actualiza el usuario actual si ha cambiado comparado con el usuario normalizado.
   *
   * @param {Object|null} newUser - El nuevo usuario autenticado o `null` si no hay usuario.
   *
   * @example
   * updateCurrentUser({ uid: '123', email: 'user@example.com' });
   */
  const updateCurrentUser = useCallback(
    newUser => {
      const userNewAuth = normalizeAuth(newUser)

      if (newUser && !isEqual(userNewAuth, currentUser)) {
        persistentStore.setUser(userNewAuth)
        setCurrentUser(userNewAuth)
      }
    },
    [currentUser]
  )

  useEffect(() => {
    // Inicializa el usuario desde el almacenamiento persistente
    const storedUser = persistentStore.getUser()
    if (storedUser) {
      updateCurrentUser(storedUser)
    }

    // Suscribirse a los cambios en la autenticación de Firebase
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (!user) {
        setLoading(false)
        return
      }

      // Si el usuario actual es diferente al usuario autenticado, actualizar el estado
      if (!currentUser || currentUser.id !== user.uid) {
        const tokenResult = await user.getIdTokenResult()
        persistentStore.setToken(tokenResult?.token)

        updateCurrentUser({
          uid: user.uid,
          photoURL: user.photoURL,
          ...tokenResult.claims
        })
      }

      setLoading(false)
    })

    // Limpia la suscripción al desmontar el componente
    return unsubscribe
  }, [updateCurrentUser, currentUser])

  /**
   * Valor memoizado del contexto de autenticación para evitar renders innecesarios.
   *
   * @type {Object}
   * @property {Object|null} currentUser - El usuario autenticado actual.
   */
  const memoizedValue = useMemo(
    () => ({
      currentUser
    }),
    [currentUser]
  )

  return (
    <AuthContext.Provider value={memoizedValue}>
      {!loading ? children : <AuthValid />}
    </AuthContext.Provider>
  )
}
